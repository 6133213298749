import React from "react"
import Layout from "../../components/layout"
import { graphql } from 'gatsby'
import ChildTermList from '../../components/ChildTermList/ChildTermList';
import Header from "../../components/header"
import SEO from "../../components/seo"
require('es6-promise').polyfill();
require('isomorphic-fetch');

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    this.checkAuthCookie = this.checkAuthCookie.bind(this);

    this.state = {
      auth: null
    }
  }

  handleClick(e) {
    e.target.parentElement.classList.toggle('display_growth_data');
  }

  handleLoad() {
    if(window.location.href.indexOf("term=") >= 0){
      var urlParams = new URLSearchParams(window.location.search);
      var termParam = urlParams.get('term'); // Get the number of paintings displayed.

      // If the url param is a term alias for a parent term run normally
      if( document.querySelector('[termLink='+termParam+']') ){
        document.querySelector('[termLink='+termParam+']').click()
      } else {
        // Else if the term link is one for the child term. Run the child click then the parent click function above
        if(document.querySelector('.child-term-button[term='+termParam+']')){
          if( document.querySelector('.child-term-button[term='+termParam+']').getAttribute('parent') ) {
            let parentAliasParam = document.querySelector('.child-term-button[term='+termParam+']').getAttribute('parent')
            document.querySelector('[termLink='+parentAliasParam+']').click()
            document.querySelector('.child-term-button[term='+termParam+']').click()
          }
        }
      }
    }
  }

  checkAuthCookie() {
    fetch('/.netlify/functions/cookie', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(response => {
      if (response.status !== 200) {
        window.location.replace('https://rsmapplications.rsmlink.global');
      } else {
        this.setState({ auth: true });
        response.json().then(data => {
          let responseMeasure = data.rsmMember;
          let jsonMeasure = JSON.parse(responseMeasure)
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'memberFirm': jsonMeasure.MemberFirmName,
          });
        });
      }
    })
    .catch(err => console.log(err));
  }

  componentDidMount() {
    this.checkAuthCookie()
  }

  componentDidUpdate() {
    window.addEventListener('load', this.handleLoad())
  }

  render() {
    if (!this.state.auth) {
      return <div />;
    }
    
    //console.log( this.props.data.drupal )
    const allTerms = this.props.data.drupal.taxonomyTermQuery.entities;
    const childTerms = [];
    const parentTerms = [];
    const allNodes = this.props.data.drupal.nodeQuery.entities;
    const allTermsLinkedWithNodes = allTerms;
    allTermsLinkedWithNodes.forEach(function(term, iiii) {     
      // Loop through all nodes
      term.nodes = [];
      allNodes.forEach(function(node_value, iv){
        if(node_value['fieldGrowthObjectiveTerm'] !== null && node_value['fieldGrowthObjectiveTerm']['entity'] !== null){
          if(node_value['fieldGrowthObjectiveTerm']['entity']['tid'] === term['tid']) {
            term.nodes.push({ node_value })
          }
        }
      })
      // Get the term alias
      if(term['path']['alias'] != null) {
        // If alias exists replace first / with #
        term['path']['alias'] = term['path']['alias'].replace('/', '')
      } else {
        // If alias doesn't exist take the name. Set to all lowercase and replace empty spaces with -
        term['path']['alias'] = term['path']['alias'].replace(/\s+/g, '-').toLowerCase()
      }

      if( term['parent'][0]['entity'] === null ) {
        
        // Is parent term
        parentTerms.push({
          'tid': term['tid'],
          'name': term['name'],
          'description': term['description'],
          'children': [],
          'nodes': term['nodes'],
          'alias': term['path']['alias'],
          'langcode': 'pt-pt',
          'icon': term['fieldTermIcon'],
          'video': term['fieldTermVideo'],
        });
      } else {
        // Is child term
        childTerms.push({
          'tid': term['tid'],
          'name': term['name'],
          'description': term['description'],
          'parent_tid': term['parent'][0]['entity']['tid'],
          'nodes': term['nodes'],
          'icon': term['fieldTermIcon'],
          'alias': term['path']['alias'],
        });
      } 
    });
    parentTerms.forEach(function(parentTermValue, i){
      childTerms.forEach(function(childTermValue, ii){
        if(parentTermValue.tid === childTermValue.parent_tid) {
          parentTerms[i].children.push({
            'tid': childTermValue['tid'],
            'name': childTermValue['name'],
            'description': childTermValue['description'],
            'nodes': childTermValue['nodes'],
            'icon': childTermValue['icon'],
            'alias': childTermValue['alias'],
          });
        }
      });
    });

    var acceptableLanguages = ['en', 'es', 'ru', 'zh-hans', 'fr', 'pt-pt'];
    const currentLanguages = [];
    this.props.data.drupal.availableLanguages.forEach(function(langInfo, r){
     if(acceptableLanguages.includes(langInfo.id)){
      currentLanguages.push({
        'name': langInfo.name,
        'argument': langInfo.argument,
        'id': langInfo.id
      })
     }
    });

    return (
      <Layout>
        <SEO title="RSM Growth Platform" />
        <Header langsToCall={currentLanguages} currentLang="pt-pt" />
        <div className="site-wrapper index-wrapper">
          <div className="index-page">
            <ul className="all_growth_objectives">
              {
                parentTerms.map( (term) => (
                  <li className={"list_item "+term.alias} key={term.tid}>
                    <div className="border-line"></div>
                    <section className="anchor-link" id={term.alias}></section>
                    <div className="parent-term-container">
                      <ChildTermList 
                      name={term.name} 
                      description={term.description} 
                      tid={term.tid} 
                      termNodes={term.nodes} 
                      termChildren={term.children}
                      termAlias={term.alias} 
                      termLangcode={term.langcode}
                      termIcon={term.icon} 
                      termVideo={term.video} />
                    </div>
                  </li> 
                ))
              }
            </ul>
          </div>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;

export const query = graphql`
  query portugeseArticleQuery {
    drupal {

      availableLanguages {
        name
        argument
        id
      }

      taxonomyTermQuery(limit: 200, sort: {direction: DESC, field: "field_term_order"}, filter: {conditions: {language: PT_PT, field: "vid", value: ["growth_objectives"], operator: EQUAL}}) {
        entities(language: PT_PT) {
          ... on Drupal_TaxonomyTermGrowthObjectives {
            tid
            name
            description {
              value
            }
            parent {
              entity {
                tid
                name
                description {
                  value
                }
              }
            }
            path {
              alias
            }
            fieldTermIcon
            fieldTermVideo
          }
        }
      }
      nodeQuery(limit: 200, filter: {conditions: {value: "growth_objective_data", field: "type", language: PT_PT}}) {
        entities(language: PT_PT) {
          ... on Drupal_NodeGrowthObjectiveData {
            nid
            entityId
            title
            path {
              langcode
              alias
            }
            fieldGrowthObjectiveTerm {
              entity {
                tid
                parent {
                  entity {
                    tid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`